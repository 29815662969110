$(function(){
	initProductFromProductPage();
	initProductFromBasketModal();
	initWishList();
	initSorting();
	initProductsStatistics();
	initProductColor();

	function initSorting(){
		$('.js-selected-list-item').click(function(){
			$(this).parent().fadeOut();
		});
	}

	function initWishList(){
		$(document).on('click', '.js-wishlist-add', function(e) {
			e.preventDefault();

			var el = this;
			if( $(this).attr('data-wishlist') == 1 ) {
				wishlist.remove( $(this).attr('data-product-id'), function() {
					$(el).data('wishlist', 0);
					$(el).removeClass('active');
				} );
			} else {
				wishlist.add( $(this).attr('data-product-id'), function() {
					$(el).data('wishlist', 1);
					$(el).addClass('active');
				} );
			}
		});
	}

	function initProductFromBasketModal(){
		initChangeQuantity();
		initChangeSize();
		initDeleteProduct();

		function initDeleteProduct(){
			$('.js-basket-modal-products-list').on('click', '.js-cart-delete', function(e) {
				e.preventDefault();
				shoppingCart.deleteProduct({
					products_id: $(this).attr('data-products-id')
				});
				$(this).closest('.js-basket-product-item').remove();
			});
		}

		function initChangeQuantity(){
			$('.js-basket-modal-products-list').on('change', '.js-basket-change-count', function(e) {
				e.preventDefault();
				shoppingCart.changeProductCount(getProductId($(this)),$(this).val());
				setNewProductData($(this));
				sendStatistics($(this));
			});

			function setNewProductData(quantityElement){
				var productDataElement = $(quantityElement).closest('.js-basket-product-item'),
					productQuantity = $(quantityElement).val();

				$(productDataElement).attr('data-quantity',productQuantity);
			}

			function getProductId(quantityElement){
				var productDataElement = $(quantityElement).closest('.js-basket-product-item'),
					productId = $(productDataElement).attr('data-product-id'),
					productOptionId = $(productDataElement).attr('data-option-id'),
					productOptionValue = $(productDataElement).attr('data-option-value');

				return productId+"{"+productOptionId+"}"+productOptionValue;
			}

			function sendStatistics(el){
				var isIncrease = $(el).attr('data-old-value') < $(el).val(),
					isDecrease = $(el).attr('data-old-value') > $(el).val();

				$(el).attr('data-old-value', $(el).val());
				var productData = $(el).closest('.js-basket-product-item'),
					productId = productData.data('product-id'),
					productName = productData.data('product-name'),
					productBrand = productData.data('product-brand'),
					productPrice = productData.data('product-price'),
					productMainCategory = productData.data('product-main-category'),
					productCategory = productData.data('product-category'),
					productQuantity = $(el).val(),
					productListName = sessionStorage.getItem('product_item_list_name_'+productId) ? sessionStorage.getItem('product_item_list_name_'+productId) : '',
					productListId = sessionStorage.getItem('product_item_list_id_'+productId) ? sessionStorage.getItem('product_item_list_id_'+productId) : '',
					productPositionInList = sessionStorage.getItem('product_index_'+productId) ? sessionStorage.getItem('product_index_'+productId) : '';

				window.dataLayer = window.dataLayer || [];
				dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object
				var dataStatistics = {
					currency: 'RUB',
					value: productPrice,
					items: [{
						item_name: productName,
						item_id: productId,
						price: productPrice,
						item_brand: productBrand,
						item_category: productMainCategory,
						item_category2: productCategory,
						item_category3: '',
						item_list_name: productListName,//�������� ������ �������
						item_list_id: productListId,// id ������ �������
						index: productPositionInList,
						quantity: productQuantity
					}]
				};
				if(isIncrease){
					dataLayer.push({
						event: 'add_to_cart',
						ecommerce: dataStatistics
					});
				}
				if(isDecrease){
					dataLayer.push({
						event: 'remove_from_cart',
						ecommerce: dataStatistics
					});
				}
			}
		}

		function initChangeSize(){
			$('.js-basket-modal-products-list').on('change', '.js-basket-change-size', function(e) {
				e.preventDefault();

				var productSizeElement = $(this).closest('.js-basket-product-item').find('.js-basket-product-size'),
					sizeText = $(this).find('option:selected').text()

				$(productSizeElement).text(sizeText);
				$.post('/ajax_shopping_cart.php?action=update_product_new',getProductData($(this)))
					.done(function( data ) {
						shoppingCart.reloadPopupCallback();
					});
				setNewProductData($(this));
			});

			function setNewProductData(sizeElement){
				var selectOptionData = $(sizeElement).val().split('|'),
					selectOptionId = selectOptionData[0],
					selectOptionValue = selectOptionData[1],
					productDataElement = $(sizeElement).closest('.js-basket-product-item');

				$(productDataElement).attr('data-option-id',selectOptionId);
				$(productDataElement).attr('data-option-value',selectOptionValue);
			}

			function getProductData(sizeElement){
				var selectOptionData = $(sizeElement).val().split('|'),
					selectOptionId = selectOptionData[0],
					selectOptionValue = selectOptionData[1],
					productDataElement = $(sizeElement).closest('.js-basket-product-item'),
					productId = $(productDataElement).attr('data-product-id'),
					productQuantity = $(productDataElement).attr('data-quantity'),
					productOptionId = $(productDataElement).attr('data-option-id'),
					productOptionValue = $(productDataElement).attr('data-option-value');

				return [
					{
						"name": "id["+productId+"{"+productOptionId+"}"+productOptionValue+"]["+productOptionId+"]",
						"value": productOptionValue
					},
					{
						"name": "products_id[]",
						"value": productId+"{"+productOptionId+"}"+productOptionValue
					},
					{
						"name": "cart_size["+productId+"{"+productOptionId+"}"+productOptionValue+"]",
						"value": productId+"{"+selectOptionId+"}"+selectOptionValue
					},
					{
						"name": "cart_quantity["+productId+"{"+productOptionId+"}"+productOptionValue+"]",
						"value": productQuantity
					}
				];
			}
		}

	}

	/**
	 * �������� � ��������
	 */
	function initProductFromProductPage() {
		initChangeSize();
		initAddToBasket();

		$('#products_buttom_info_delivery').ajaxForm({
			success: function(data) {
				let $deliveryWrapper = $('.js-product-delivery');
				$('.js-product-delivery-list', $deliveryWrapper).show();
				$('.product-delivery-choose-size', $deliveryWrapper).hide().removeClass('loading');
				$('#product-delivery-res', $deliveryWrapper).html(data.content[0]);
				$('#product-delivery-pre-title-res', $deliveryWrapper).html(data.content[1]);
			}
		});
		let $deliveryDescrForm = $('#products_buttom_info_delivery');
		if ( $deliveryDescrForm.data('onload') == 1 ) {
			$deliveryDescrForm.submit();
		} else {
			let option = $('.js-add-product').closest('.js-product-data').data('optionValue');
			if ( option > 0 ) {
				let $deliveryDescrForm = $('#products_buttom_info_delivery');
				if ( $deliveryDescrForm.length ) {
					$('input[name=for_product]', $deliveryDescrForm).val($('.js-add-product').data('productId') + '{1}' + option);
					$deliveryDescrForm.submit();
				}
			}
		}

		function initAddToBasket() {
			$(document).on('click', '.js-add-product', function (e) {
				if(typeof generalPixel === "object") {
					generalPixel.postClick('a1')
				}

				var me = $(this),
					isDisabled = getProductSizeDisabled(me);

				if(isDisabled){
					return true;
				}

				var productData = getProductDataArr(me);

				if(productData.length) {
					$(this).addClass('m-preloader');
					shoppingCart.addProducts({
						product_info: productData,
					}, {
						checkDublicate: false
					});
					$('.js-basket-modal-block').addClass('active');
				}
			});
		}

		function getProductSizeDisabled(element){
			if($(element).attr('data-option-disabled')){
				return Number($(element).attr('data-option-disabled'));
			}

			var dataProductEl = $(element).closest('.js-product-data');

			return Number($(dataProductEl).attr('data-option-disabled'));
		}

		function initChangeSize(){
			$('.j-info-shop-product-size').change(function(){
				$('.js-selected-size-value').text($('.j-info-shop-product-size option:selected').text());
			});
			$(document).on('click', '.js-change-product-size', function (e) {
				var dataProductEl = $(this).closest('.js-product-data');

				e.preventDefault();
				$(dataProductEl).attr('data-option-name',$(this).attr('data-option-name'))
					.attr('data-option-value',$(this).attr('data-option-value'))
					.attr('data-option-disabled',$(this).attr('data-option-disabled'));
				setActiveSize($(this));
				setPopupSize($(this));

				function setPopupSize(currentSizeEl){
					var isDisabledSize = 1*($(currentSizeEl).attr('data-option-disabled'));

					if(!isDisabledSize){
						$('.j-info-shop-product-size').val($(currentSizeEl).attr('data-option-value')).trigger('change');
						$('.select-j-info-shop-product-size .label').text($('.j-info-shop-product-size option:selected').text());
					}
				}
				function setActiveSize(currentSizeEl){
					$(currentSizeEl).closest('.js-product-sizes-list').find('.js-change-product-size').removeAttr('data-selected');
					$(currentSizeEl).attr('data-selected','true');
					if($(currentSizeEl).hasClass('empty')){
						$('.js-add-product').attr('href',$(currentSizeEl).attr('data-same-products-link')).html('������ ������ ������� '+$(currentSizeEl).html());
					}else{
						$('.js-add-product').removeAttr('href').text('�������� � �������');
						let $deliveryDescrForm = $('#products_buttom_info_delivery');
						if ( $deliveryDescrForm.length ) {
							$('input[name=for_product]', $deliveryDescrForm).val($('.js-add-product').data('productId') + '{1}' + currentSizeEl.attr('data-option-value'));
							$deliveryDescrForm.submit();
						}
					}
				}
			});
		}

		function getProductDataArr(element){
			var hasDataProductEl = $(element).closest('.js-product-data').length;

			if(!hasDataProductEl){
				return [];
			}

			var dataProductEl = $(element).closest('.js-product-data');

			return [
				{
					"name": 'id'+($(element).attr('data-option-name') ? $(element).attr('data-option-name') : $(dataProductEl).attr('data-option-name')),
					"value": $(element).attr('data-option-value') ? $(element).attr('data-option-value') : $(dataProductEl).attr('data-option-value')
				},
				{
					"name": "cart_quantity",
					"value": $(dataProductEl).attr('data-cart-quantity')
				},
				{
					"name": "products_id",
					"value": $(dataProductEl).attr('data-products-id')
				},
				{
					"name": "param",
					"value": $(dataProductEl).attr('data-param')
				},
				{
					"name": "presents_artikul",
					"value": $(dataProductEl).attr('data-presents-artikul')
				},
				{
					"name": "selected_attribute",
					"value": $(dataProductEl).attr('data-selected-attribute')
				}
			];
		}
	}

	function initProductsStatistics(){
		$('.js-catalog-products-list').on('click','.js-add-product',function(){
			const PRODUCTS_ON_PAGE = 38;

			var currentPage = $('#filtr-form [name="page"]').length ? $('#filtr-form [name="page"]').val() : 1,
				productData = $(this).closest('.js-product-data'),
				productId    = productData.data('products-id'),
				productName  = productData.data('product-name'),
				productPrice = productData.data('product-price'),
				productBrand = productData.data('product-brand'),
				productMainCategory = productData.data('product-main-category'),
				productCategory = productData.data('product-category'),
				productListName = $('h1.c__title').length ? $('h1.c__title').text() : '',

				productIndex = (1*(1 + $('.js-catalog-products-list').children('.js-product-data').index(productData))) + (PRODUCTS_ON_PAGE*(currentPage - 1));

			window.dataLayer = window.dataLayer || [];
			dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object
			var dataStatistics = {
				currency: 'RUB',
				value: productPrice,
				items: [{
					item_name: productName,
					item_id: productId,
					price: productPrice,
					item_brand: productBrand,
					item_category: productMainCategory,
					item_category2: productCategory,
					item_category3: '',
					item_list_name: productListName,//�������� ������ �������
					item_list_id: location.pathname,// id ������ �������
					index: productIndex,
					quantity: 1
				}]
			};
			dataLayer.push({
				event: 'add_to_cart',
				ecommerce: dataStatistics,
			});

			sessionStorage.setItem('product_item_list_name_'+productId, productListName);
			sessionStorage.setItem('product_item_list_id_'+productId, location.pathname);
			sessionStorage.setItem('product_index_'+productId, productIndex);
		});
	}

	function initProductColor(){
		$('.js-catalog-products-list').on('mouseenter', '.js-color-item', function() {
			var productInfo = $(this).data('color-info'),
				productEl = $(this).closest('.js-product-data');

			setPrice(productEl, productInfo);
			setImage(productEl, productInfo);
			setSize(productEl, productInfo);

			$('.inner .visual .js-product-link img', productEl).attr('src', productInfo.firstSrc);
			if($('.inner .visual .btn-panel .j-fast-view-btn', productEl).length) {
				$('.inner .visual .btn-panel .j-fast-view-btn', productEl).data('href', href);
			}
			// wishlist - start
			var inWishlist = window.wishlist.list.indexOf( productInfo.id ) != -1 ? 1 : 0 ;
			var wishlistBtn = $('.inner .visual .btn-panel .j-wishlist-add', productEl);
			wishlistBtn.data('products_id', productInfo.id);
			wishlistBtn.data('wishlist', inWishlist);
			wishlistBtn[ inWishlist ? 'addClass' : 'removeClass' ]('active');
			// wishlist - end
			$('.inner .product-description .article', productEl).text( productInfo.name );
			if ( productInfo.coupon_price > 0 && productInfo.coupon_price < parseInt(productInfo.cprice) ) {
				var priceWithDiscountText = productInfo.coupon_price_text + '<span class="old-price">' + productInfo.price + '</span><span class="pct">(-' + productInfo.coupon_pct + '%)</span>';

				if ( false && productInfo.product_price_history.length ) {
					priceWithDiscountText += '<br><span class="coupon-price-label">(� ������ �����)</span>';
					priceWithDiscountText += '<div class="discount-description">';
					for(var discount in productInfo.product_price_history){
						let item = productInfo.product_price_history[discount];

						priceWithDiscountText += '<div>';
						priceWithDiscountText += '<span class="discount-description-title">'+ item.name + '</span>';
						priceWithDiscountText += '<span class="discount-description-diff">'+ (item.after - item.before) + ' ���</span>';
						priceWithDiscountText += '</div>';
					}
					priceWithDiscountText += '</div>';
				}
				$('.inner .price', productEl).html( priceWithDiscountText );
			} else if( productInfo.disPct > 0 ) {
				if(productInfo.coupon_pct){
					$('.inner .price', productEl).html(productInfo.coupon_price_text + '<span class="old-price">' + productInfo.price + '</span><span class="pct">(-' + productInfo.disPct + '%)</span>');
				}else {
					$('.inner .price', productEl).html(productInfo.cprice + '<span class="old-price">' + productInfo.price + '</span><span class="pct">(-' + productInfo.disPct + '%)</span>');
				}
			} else {
				$('.inner .price', productEl).html( productInfo.price );
			}
			renderProductSize(productEl,productInfo);
		}).on('mouseleave', '.js-product-data', function(e) {
			var productEl = $(this),
				productInfo = {
					cprice:$('.js-price-current',productEl).attr('data-price-current-default'),
					price:$('.js-price-old',productEl).attr('data-price-old-default'),
				};

			setPrice(productEl, productInfo);
		});

		function setPrice(productEl, productInfo){
			$('.js-price-current',productEl).text(productInfo.cprice);
			$('.js-price-old',productEl).text(productInfo.price);
		}

		function setImage(productEl, productInfo){
			var imagesCount = '';
			product.imgGallery = [];
			for (var i=1; i<=product.products_images_count; i++){
				product.imgGallery.push({
					key:i,
					products_image: product.products_bimage.replace("_1", "_"+i),
					products_name: product.products_name
				});
			}
		}

		function setSize(){
		}

		function renderProductSize(productEl,productInfo){
			$('.hover_size [name="products_id"]',productEl).val(productInfo.id).attr('value',productInfo.id);

			var sizesDom = $('.hover_size .sizes',productEl);

			if(!sizesDom){
				return false;
			}

			var sizesData = productInfo.sizes,
				newHtml = '';

			if(!sizesData.length){
				$(sizesDom).html(newHtml);
				return false;
			}

			for(var size of sizesData){
				if(!size.id || !size.text){
					continue;
				}
				newHtml += '<li><label><input type="radio" name="id[1]" value="'+size.id+'" style="display:none;">'+size.text+'</label></li>';
			}

			$(sizesDom).html(newHtml);
		}
	}
});